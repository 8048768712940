<template>
    <div class="process-manage">
        <div class="btn-groups">
            <div class="el_right generalQuery">
                <el-input
                    placeholder="请输入搜索内容..."
                    v-model="searchKey"
                    class="input-with-select"
                    @keydown.native.enter="getUserList"
                >
                    <el-button slot="append" icon="el-icon-search" @click="getUserList"></el-button>
                </el-input>
            </div>
        </div>
        <div class="page-list">
            <el-table
                border
                stripe
                :data="tableList"
                ref="multipleTable"
                :highlight-current-row="true"
                v-loading="loading"
                height="calc(100% - 0.4rem)"
            >
                <el-table-column
                    label="流程名称"
                    prop="BusinessName"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    label="流程类别"
                    prop="BusinessType"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    label="流程编码"
                    prop="BusinessCode"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    label="是否启用"
                    prop="Enabled"
                    align="center"
                >
                    <template slot-scope="scope">
                        {{ scope.row.Enabled?'是':'否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    label="创建时间"
                    prop="CreateDate"
                    align="center"
                >
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="200"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div class="process-set" @click="processSet(scope.row)">
                            流程设置
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-page">
                <div class="el_right">
                    <el-pagination
                        layout="total, sizes, prev, pager, next, jumper"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page"
                        :page-sizes="[20,40,60,80,100]"
                        :page-size="limit"
                        :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'process-manage',
    data() {
        return {
            loading: false,
            tableList: [],
            dialogTitle: '',
            dialogFormVisible: false,
            searchKey: '',
            page: 1,
            limit: 20,
            total: 0,

            curUser: {},
            showEditDialog: false,
            editTitle: '',
        };
    },
    created() {
        this.getUserList();
    },
    methods: {
        // 获取组织列表
        async getUserList() {
            this.loading = true;
            const data = await this.$axios.get('/interfaceApi/basicplatform/simpleflow/config/getbusinesspage?'
                    + `page=${this.page}&rows=${this.limit}&search=${this.searchKey}`);
            this.tableList = data.List;
            this.total = data.PageCount;
            this.loading = false;
        },

        // 流程设置
        processSet(item) {
            this.$toast({
                width: '15rem',
                height: '9rem',
                type: 'sideslip',
                t_url: 'admin/processManage/processSet/index',
                extr: {
                    businessid: item.BusinessId,
                },
            });
        },
        handleSucc() {
            this.handleClose();
            this.getUserList();
        },
        handleClose() {
            this.showEditDialog = false;
        },
        // 设置每页条数
        handleSizeChange(val) {
            this.limit = val;
            this.getUserList();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.page = val;
            this.getUserList();
        },
    },
};
</script>

<style lang="stylus" scoped>
    .process-manage
        position relative
        width 100%;
        height 100%;
        padding: 0.2rem;
        background: #f0f0f0;
        .btn-groups
            box-sizing content-box
            padding-left 0.1rem;
            padding-top 0.2rem;
            background #fff;
            height: 0.4rem;
            display flex
            align-items center
            > *
                margin 0 0.2rem;
            .generalQuery
                height 100%;
                margin: 0.05rem 0
                >>> .el-input
                    height 100%;
                    .el-input__inner
                        width: 3rem;
                        height 100%;
                        font-size: .14rem;
                    .el-input-group__append
                        border: 1px solid #1577D2;
                        padding: 0 .1rem;
                        overflow: hidden;
                        width: .6rem;
                        font-size: .2rem;
                        text-align: center;
                        color #fff
                        .el-button
                            background: #1577D2;
                .el-input__inner
                    width 3rem
                    font-size 0.14rem
                .el-input-group__append
                    overflow hidden
                    width 0.6rem
                    font-size 0.2rem
                    text-align center
            .add-role
                padding 0
                width: 1.22rem;
                height: 0.4rem;
                background: #1577D2;
                border-radius: 0.02rem;
                font-size 0.16rem;
                color #fff;
                z-index: 2;
        .page-list
            width 100%;
            height calc(100% - 0.6rem);
            background #fff;
            padding 0.1rem;
            .el-table
                >>> th
                    background: #EDF0F5;
                    border: 1px solid #DEDFE0;
                    color #022782
                th,td
                    padding 0
                .process-set
                    margin 0 auto;
                    display flex
                    justify-content center
                    align-items center
                    width: 0.9rem;
                    height: 0.28rem;
                    border-radius: 0.02rem;
                    border: 1px solid #0285FE;
                    font-size 0.16rem;
                    color #0285FE;
                    cursor pointer
</style>
